import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useRef, useState } from "react";
import { ButtonGroup, Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import { axiosInstance } from "apiInstances";
import useEncryption from "EncryptDecrypt/EncryptDecrypt";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Tree from "react-d3-tree";
import clipboardCopy from "clipboard-copy";
import { ToastContainer, toast } from "react-toastify";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
const ViewOneUser = () => {
  const nodeRef = useRef(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const getPath = queryParams.get("walletAddress");
  const createdAt = queryParams.get("createdAt");
  const updatedAt = queryParams.get("updatedAt");
  const id = queryParams.get("id");
  const [totalstaked, settotalStaked] = useState();
  const [totalstakingProfit, settotalstakingProfit] = useState();
  console.log("🚀 ~ ViewOneUser ~ totalstakingProfit:", totalstakingProfit)
  const [totalsreferalProfit, settotalsreferalProfit] = useState();
  console.log("🚀 ~ ViewOneUser ~ totalsreferalProfit:", totalsreferalProfit)

  const formattedCreatedAt = formatDate(createdAt);
  const formattedUpdatedAt = formatDate(updatedAt);

  const { decryptData } = useEncryption();
  const [rows, setRows] = useState([]);

  const [rows1, setRows1] = useState([]);
  let dataList = [];
  const [selectedOption, setSelectedOption] = useState("Staking");
  const [selectedStakingOption, setSelectedStakingOption] = useState("WEC");

  // console.log("selectedOption", selectedOption);
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setPage(1);
    setCurrentPage(1);
  };
  const handleStakingClick = (option) => {
    setSelectedStakingOption(option);
    setPage(1);
    setCurrentPage(1);
  };


  //tree

  const [orgChart, setOrgChart] = useState({
    name: "Root",
    children: [],
  });
  const handleNodeClick = (node) => {
    // console.log("------------------->", node.name);
    clipboardCopy(node.name);
    {
      selectedOption === "Referallist" && clipboardCopy(node);
    }
    toast.success("wallet address copied");
  };

  //pagination
  const [hoveredNode, setHoveredNode] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalWithdraw, setTotalWithdraw] = useState();
  //format date from params
  const temp = [];
  function formatDate(timestamp) {
    const dateTime = new Date(timestamp);
    const day = dateTime.getDate().toString().padStart(2, "0");
    const month = (dateTime.getMonth() + 1).toString().padStart(2, "0");
    const year = dateTime.getFullYear();
    const hours = dateTime.getUTCHours().toString().padStart(2, "0");
    const minutes = dateTime.getUTCMinutes().toString().padStart(2, "0");
    const seconds = dateTime.getUTCSeconds().toString().padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

  useEffect(() => {
    const startIndex = (currentPage - 1) * entriesPerPage;
    const endIndex = startIndex + entriesPerPage;

    const getRef = async () => {
      try {
        const res = await axiosInstance.get(`/refaralDetails/${id}`);
        const responseData = decryptData(res?.data?.data);
        console.log(
          "🚀 ~ file: ViewOneUser.js:87 ~ getRef ~ responseData:",
          JSON.parse(responseData?.data)
        );
        const JsonParsedData = JSON.parse(responseData?.data);
        console.log("🚀 ~ getRef ~ JsonParsedData:", JsonParsedData);

        let data = {};

        try {
          data = JSON.parse(responseData?.data);
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }

        // root node
        const orgChart = {
          name: getPath,
          children: [],
        };
        console.log("🚀 ~ file: ViewOneUser.js:101 ~ getRef ~ orgChart:", orgChart);
        dataList.push(getPath);

        if (data.referrals && data.referrals.length > 0) {
          data.referrals.slice(0, 5).forEach((item) => {
            //  node 1
            const childNode = {
              name: item?.user?.walletAddress,
              children: [],
              id: item.user?._id,
            };
            {
              selectedOption === "Referallist" && dataList.push(childNode.name);
            }

            if (item.referrals && item.referrals.length > 0) {
              item.referrals.slice(0, 5).forEach((referralItem) => {
                //  node 2
                const grandChildNode = {
                  name: referralItem.user?.walletAddress,
                  children: [],
                };
                {
                  selectedOption === "Referallist" && dataList.push(grandChildNode.name);
                }

                if (referralItem.referrals && referralItem.referrals.length > 0) {
                  referralItem.referrals.slice(0, 5).forEach((innerReferralItem) => {
                    //  node 3
                    const level3ChildNode = {
                      name: innerReferralItem.user?.walletAddress,
                      children: [],
                    };
                    {
                      selectedOption === "Referallist" && dataList.push(level3ChildNode.name);
                    }

                    if (innerReferralItem.referrals && innerReferralItem.referrals.length > 0) {
                      innerReferralItem.referrals.slice(0, 5).forEach((level4Item) => {
                        //  node 4
                        const level4ChildNode = {
                          name: level4Item.user?.walletAddress,
                          children: [],
                        };
                        {
                          selectedOption === "Referallist" && dataList.push(level4ChildNode.name);
                        }

                        if (level4Item.referrals && level4Item.referrals.length > 0) {
                          level4Item.referrals.slice(0, 5).forEach((level5Item) => {
                            //  node 5
                            const level5ChildNode = {
                              name: level5Item.user?.walletAddress,
                              children: [],
                            };
                            {
                              selectedOption === "Referallist" &&
                                dataList.push(level5ChildNode.name);
                            }

                            // Add node 5 to node 4
                            level4ChildNode.children.push(level5ChildNode);
                          });
                        }

                        // Add node 4 to node 3
                        level3ChildNode.children.push(level4ChildNode);
                      });
                    }

                    // Add node 3 to node 2
                    grandChildNode.children.push(level3ChildNode);
                  });
                }

                // Add node 2 to node 1
                childNode.children.push(grandChildNode);
              });
            }

            // Add node 1 to orgChart
            orgChart.children.push(childNode);
          });
        }

        const temp1 = dataList.slice(startIndex, endIndex).map((item, index) => ({
          id: startIndex + index + 1,
          walletAddress: item,
          CopyWalletAddress: (
            <button onClick={() => handleNodeClick(item)}>
              <ContentCopyIcon />
            </button>
          ),
        }));
        setPage(Math.ceil(dataList.length / entriesPerPage));

        {
          selectedOption === "Referallist" && setRows1(temp1);
        }
        setOrgChart(orgChart);
      } catch (error) {
        console.error("Error fetching referral data:", error);
      }

      setRows(temp);
    };
    const fetchData = async () => {
      if (selectedOption === "Staking") {
        const res = await axiosInstance.get(`/profitWithdrawHistory/${getPath}`);
        const responseData = decryptData(res?.data?.data);
        console.log("🚀🚀🚀 ~ responseData ~ data:", responseData);
        const data = selectedStakingOption === "WEC" ? responseData?.data?.stakingHistory : responseData?.data?.ABTstakingHistory;
        console.log("🚀 ~ fetchData ~ data:", data);
        if (data) {
          const temp = data.slice(startIndex, endIndex).map((item, index) => ({
            ...item,
            id: startIndex + index + 1,
            Date: formatDate(item.createdAt).slice(0, 10),
            Amount: item.amount,
            duration: item.duration,
          }));
          const calculateTotalStakedAmounts = (data) => {
            return data.reduce((total, item) => total + item.amount, 0);
          };
          const totalStakedAmounts = calculateTotalStakedAmounts(data);
          settotalstakingProfit(selectedStakingOption === "WEC" ? responseData?.data?.user?.profit.toFixed(2) : responseData?.data?.user?.ABTStakingProfit.toFixed(2));
          settotalsreferalProfit(selectedStakingOption === "WEC" ? responseData?.data?.user?.refaralProfit.toFixed(2) : responseData?.data?.user?.ABTStakingRefaralProfit.toFixed(2));
          settotalStaked(totalStakedAmounts);
          setPage(Math.ceil(data.length / entriesPerPage));
          setRows(temp);
        }
      } else if (selectedOption === "Withdraw") {
        const res = await axiosInstance.get(`/profitWithdrawHistory/${getPath}`);
        const responseData = decryptData(res.data.data);
        const data = selectedStakingOption === "WEC" ? responseData?.data?.withdrawHistory : responseData?.data?.ABTwithdrawHistory;
        // console.log("data", responseData);
        const totalWithdraw = selectedStakingOption === "WEC" ? responseData?.data?.totalWithdraw : responseData?.data?.totalABTWithdraw;
        if (totalWithdraw) {
          setTotalWithdraw(totalWithdraw);
        } else {
          setTotalWithdraw(0);
        }
        if (data) {
          const temp = data.slice(startIndex, endIndex).map((item, index) => ({
            id: startIndex + index + 1,
            Date: formatDate(item.createdAt).slice(0, 10),
            Amount: item.amount,
            staking: item.duration,
          }));
          setPage(Math.ceil(data.length / entriesPerPage));

          setRows(temp);
        }
      } else if (selectedOption === "Referal" || selectedOption === "Referallist") {
        getRef();
      } else if (selectedOption === "Referallist") {
        try {
          const res = await axiosInstance.get(`/refaralDetails/${id}`);
          const responseData = decryptData(res?.data?.data);
          let data = {};
          console.log("dataList", dataList);
          try {
            data = JSON.parse(responseData?.data);
          } catch (error) {
            console.error("Error parsing JSON:", error);
          }
          const orgChart = {
            name: getPath,
            children: [],
          };
          dataList.push(getPath);
          // console.log("orgChart", orgChart);
          // console.log("referal----------------------->", orgChart);
          if (data.referrals && data.referrals.length > 0) {
            data.referrals.slice(0, 5).forEach((item) => {
              //  node 1
              const childNode = {
                name: item?.user?.walletAddress,
                children: [],
                id: item.user?._id,
              };
              dataList.push(childNode.name);

              if (item.referrals && item.referrals.length > 0) {
                item.referrals.slice(0, 5).forEach((referralItem) => {
                  //  node 2
                  const grandChildNode = {
                    name: referralItem.user?.walletAddress,
                    children: [],
                  };
                  dataList.push(grandChildNode.name);

                  if (referralItem.referrals && referralItem.referrals.length > 0) {
                    referralItem.referrals.slice(0, 5).forEach((innerReferralItem) => {
                      //  node 3
                      const level3ChildNode = {
                        name: innerReferralItem.user?.walletAddress,
                        children: [],
                      };
                      dataList.push(level3ChildNode.name);

                      if (innerReferralItem.referrals && innerReferralItem.referrals.length > 0) {
                        innerReferralItem.referrals.slice(0, 5).forEach((level4Item) => {
                          //  node 4
                          const level4ChildNode = {
                            name: level4Item.user?.walletAddress,
                            children: [],
                          };
                          dataList.push(level4ChildNode.name);

                          if (level4Item.referrals && level4Item.referrals.length > 0) {
                            level4Item.referrals.slice(0, 5).forEach((level5Item) => {
                              //  node 5
                              const level5ChildNode = {
                                name: level5Item.user?.walletAddress,
                                children: [],
                              };
                              dataList.push(level5ChildNode.name);

                              // Add node 5 to node 4
                              level4ChildNode.children.push(level5ChildNode);
                            });
                          }

                          // Add node 4 to node 3
                          level3ChildNode.children.push(level4ChildNode);
                        });
                      }

                      // Add node 3 to node 2
                      grandChildNode.children.push(level3ChildNode);
                    });
                  }

                  // Add node 2 to node 1
                  childNode.children.push(grandChildNode);
                });
              }

              const temp = dataList.slice(startIndex, endIndex).map((item, index) => ({
                id: startIndex + index + 1,
                walletAddress: item,
              }));
              setPage(Math.ceil(dataList.length / entriesPerPage));
              setRows(temp);
            });
          }
        } catch (error) {
          console.error("Error fetching referral data:", error);
        }
      }
    };
    fetchData();
  }, [selectedOption, selectedStakingOption, getPath, currentPage, page]);

  const columns = [
    { Header: "ID", accessor: "id" },

    ...(selectedOption === "Referal" || selectedOption === "Referallist"
      ? [{ Header: "walletAddress", accessor: "walletAddress" }]
      : []),
    ...(selectedOption === "Withdraw" || selectedOption === "Staking"
      ? [{ Header: "Date", accessor: "Date" }]
      : []),
    ...(selectedOption === "Referallist" ? [] : [{ Header: "Amount", accessor: "Amount" }]),
    ...(selectedOption === "Referallist"
      ? [{ Header: "CopyWalletAddress", accessor: "CopyWalletAddress" }]
      : []),

    ...(selectedOption === "Staking" ? [{ Header: "duration", accessor: "duration" }] : []),
  ];

  const handlePageChange = (event, newPage) => {
    if (typeof newPage === "number") {
      setCurrentPage(newPage);
      // console.log("new page: " + newPage);
    }
  };

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <div className="text-lg mb-12">
          <h3 className="text-lg mb-4">
            Wallet Address:{" "}
            <span className="lg:text-blue-800 md:text-blue-800 font-semibold lg:text-xl md:text-xl text-base text-blue-800">
              {getPath}
            </span>
          </h3>

          <div className="flex gap-10">
            <div>
              Created Date :{" "}
              <span className="lg:text-blue-800 md:text-blue-800 font-semibold lg:text-xl md:text-xl text-base text-blue-800">
                {formattedCreatedAt.slice(0, 10)}{" "}
              </span>
            </div>
            <div>
              Time :
              <span className="lg:text-blue-800 md:text-blue-800 font-semibold lg:text-xl md:text-xl text-base text-blue-800">
                {formattedCreatedAt.slice(10)}{" "}
              </span>
            </div>
          </div>
          <div className="flex gap-10">
            <div>
              Updated Date :{" "}
              <span className="lg:text-blue-800 md:text-blue-800 font-semibold lg:text-xl md:text-2l text-base text-blue-800">
                {formattedUpdatedAt.slice(0, 10)}{" "}
              </span>
            </div>
            <div>
              Time :
              <span className="lg:text-blue-800 md:text-blue-800 font-semibold lg:text-xl md:text-xl text-base text-blue-800">
                {formattedUpdatedAt.slice(10)}{" "}
              </span>
            </div>
          </div>

          <div className="flex gap-10 mt-5">
            <h3 className="text-lg mb-4">
              Staked Amount:{" "}
              <span className="lg:text-blue-800 md:text-blue-800 font-semibold lg:text-xl md:text-xl text-base text-blue-800">
                {totalstaked}
              </span>
            </h3>
            <h3 className="text-lg mb-4">
              Staking Profit:{" "}
              <span className="lg:text-blue-800 md:text-blue-800 font-semibold lg:text-xl md:text-xl text-base text-blue-800">
                {totalstakingProfit}
              </span>
            </h3>
            <h3 className="text-lg mb-4">
              Referal Profit:{" "}
              <span className="lg:text-blue-800 md:text-blue-800 font-semibold lg:text-xl md:text-xl text-base text-blue-800">
                {totalsreferalProfit}
              </span>
            </h3>
          </div>
        </div>

        <MDBox
          mx={2}
          mt={-3}
          py={3}
          px={2}
          variant="gradient"
          bgColor="coinTitleColor"
          borderRadius="lg"
          coloredShadow="info"
        >
          <div className="box-user ">
            <MDTypography variant="h6" color="white" style={{ fontSize: "1.5rem" }}>
              {selectedOption} Data
            </MDTypography>
            <MDTypography variant="h6" color="white" style={{ fontSize: "1.5rem" }}>
              {selectedOption === "Withdraw" ? ` Total: ${totalWithdraw}` : " "}
            </MDTypography>
          </div>
        </MDBox>


        <div className="text-center  mt-5">
          <ButtonGroup variant="contained" color="primary">
            <Button
              onClick={() => handleOptionClick("Staking")}
              variant={selectedOption === "Staking" ? "contained" : "outlined"}
              style={{
                backgroundColor: selectedOption === "Staking" ? "blue" : "white",
                color: selectedOption === "Staking" ? "white" : "blue",
              }}
            >
              Staking
            </Button>
            <Button
              onClick={() => handleOptionClick("Referal")}
              variant={selectedOption === "Referal" ? "contained" : "outlined"}
              style={{
                backgroundColor: selectedOption === "Referal" ? "blue" : "white",
                color: selectedOption === "Referal" ? "white" : "blue",
              }}
            >
              referal
            </Button>

            <Button
              onClick={() => handleOptionClick("Referallist")}
              variant={selectedOption === "Referallist" ? "contained" : "outlined"}
              style={{
                backgroundColor: selectedOption === "Referallist" ? "blue" : "white",
                color: selectedOption === "Referallist" ? "white" : "blue",
              }}
            >
              referal list
            </Button>
            <Button
              onClick={() => handleOptionClick("Withdraw")}
              variant={selectedOption === "Withdraw" ? "contained" : "outlined"}
              style={{
                backgroundColor: selectedOption === "Withdraw" ? "blue" : "white",
                color: selectedOption === "Withdraw" ? "white" : "blue",
              }}
            >
              Withdraw
            </Button>
          </ButtonGroup>
        </div>
        {(selectedOption === "Staking" || selectedOption === "Withdraw") && <div className="text-center mt-5">
          <ButtonGroup variant="contained" color="primary">
            <Button
              onClick={() => handleStakingClick("WEC")}
              variant={selectedStakingOption === "WEC" ? "contained" : "outlined"}
              style={{
                backgroundColor: selectedStakingOption === "WEC" ? "blue" : "white",
                color: selectedStakingOption === "WEC" ? "white" : "blue",
              }}
            >
              WEC
            </Button>
            <Button
              onClick={() => handleStakingClick("ABT")}
              variant={selectedStakingOption === "ABT" ? "contained" : "outlined"}
              style={{
                backgroundColor: selectedStakingOption === "ABT" ? "blue" : "white",
                color: selectedStakingOption === "ABT" ? "white" : "blue",
              }}
            >
              ABT
            </Button>
          </ButtonGroup>
        </div>}
        <MDBox
          pt={3}
          pb={3}
          className={`flex justify-center ${selectedOption === "Staking" ? "space-x-4" : "  "}`}
        >
          {(selectedOption === "Staking") && (
            <>
              {rows.some((row) => row.duration === 6) && (
                <DataTable
                  table={{ columns: columns, rows: rows.filter((row) => row.duration === 6) }}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              )}
              {rows.some((row) => row.duration !== 6) && (
                <DataTable
                  table={{ columns: columns, rows: rows.filter((row) => row.duration !== 6) }}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              )}
              {!rows.some((row) => row.duration === 6) &&
                !rows.some((row) => row.duration !== 6) && (
                  <h1 className="mt-16  text-4xl font-bold text-blue-600">
                    No {selectedOption} Data Found
                  </h1>
                )}
            </>
          )}

          {selectedOption === "Withdraw" &&
            (rows.length > 0 ? (
              <DataTable
                table={{ columns: columns, rows: rows }}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              />
            ) : (
              <h1 className="mt-16  text-4xl font-bold text-blue-600">
                No {selectedOption} Data Found
              </h1>
            ))}
          {selectedOption === "Referallist" &&
            (rows1.length > 1 ? (
              <DataTable
                table={{ columns: columns, rows: rows1 }}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              />
            ) : (
              <h1 className="mt-16  text-4xl font-bold text-blue-600">
                No {selectedOption} Data Found
              </h1>
            ))}

          {selectedOption === "Referal" &&
            (orgChart?.children?.length > 0 ? (
              <div style={{ width: "100%", height: "500px" }}>
                <Tree
                  data={orgChart}
                  translate={{ x: 500, y: 50 }}
                  orientation="radial"
                  // pathFunc="diagonal"
                  // nodeSize={{ x: 50, y: 150 }}
                  allowForeignObjects
                  // transitionDuration={100}

                  renderCustomNodeElement={({ nodeDatum }) => (
                    <g
                      className="rd3t-node"
                      onClick={() => handleNodeClick(nodeDatum)}
                      onMouseEnter={() => setHoveredNode(nodeDatum)}
                      onMouseLeave={() => setHoveredNode(null)}
                    >
                      <circle r={15} fill="lightblue" style={{ cursor: "pointer" }} />
                      <foreignObject width={500} height={50}>
                        <div
                          ref={nodeRef}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                            visibility: hoveredNode === nodeDatum ? "visible" : "hidden",
                          }}
                        >
                          {nodeDatum.name}
                        </div>
                      </foreignObject>
                    </g>
                  )}
                />
              </div>
            ) : (
              <h1 className="mt-16  text-4xl font-bold text-blue-600">
                No {selectedOption} Data Found
              </h1>
            ))}
        </MDBox>

        {rows.length > 0 && (
          <Stack padding={2}>
            <Pagination onChange={handlePageChange} count={page} size="large" />
          </Stack>
        )}
        {selectedOption === "Referallist" && (
          <div>
            {rows1.length > 0 && (
              <Stack padding={2}>
                <Pagination onChange={handlePageChange} count={page} size="large" />
              </Stack>
            )}
          </div>
        )}
      </DashboardLayout>
      <ToastContainer />
    </>
  );
};
export default ViewOneUser;
